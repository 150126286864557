import { IconHelpRegular, IconIosShareRegular } from "@seed-design/icon";
import React, { useEffect } from "react";

import { openInAppBrowser } from "../../../shared/lib/webview-link-router/webviewLinkRouter";
import { Flex } from "../../../shared/ui/layout/Flex";
import { preloadComponent } from "../../../stackflow/routes/preloadComponent";
import { useNavigator } from "../../../stackflow/useNavigator";
import { SOCIAL_CAPITAL_FAQ_URL } from "../model/constants";
import * as css from "./AppBarAdditionalButton.css";

interface Props {
  iconColor?: string;
}
const AppBarAdditionalButton = ({ iconColor }: Props) => {
  const { push } = useNavigator();

  const onClickHelp = () => {
    openInAppBrowser({ url: SOCIAL_CAPITAL_FAQ_URL });
  };

  const onClickShare = () => {
    push("social_capital.bottom_sheet.share", {});
  };

  useEffect(() => {
    preloadComponent("social_capital.bottom_sheet.share");
  }, []);

  return (
    <Flex align="center" className={css.base}>
      <button className={css.button} onClick={onClickHelp}>
        <IconHelpRegular width="100%" height="100%" color={iconColor} />
      </button>

      <button className={css.button} onClick={onClickShare}>
        <IconIosShareRegular width="100%" height="100%" color={iconColor} />
      </button>
    </Flex>
  );
};

export default AppBarAdditionalButton;
