import { BottomSheet, type BottomSheetProps } from "@stackflow/plugin-basic-ui";
import { type MouseEvent } from "react";

import * as css from "./ActionSheet.css";

type ActionType = "default" | "destructive";
type ActionButtonType =
  | {
      label: string;
      onClick: (e: MouseEvent<HTMLButtonElement>) => void;
      type?: ActionType;
    }
  | undefined;
interface Props extends Omit<BottomSheetProps, "children"> {
  dismissButton?: {
    label: string;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  };
  actionButtons?: ActionButtonType[];
}
export const ActionSheet = ({
  dismissButton,
  actionButtons,
  onOutsideClick,
}: Props) => {
  return (
    <BottomSheet
      onOutsideClick={
        onOutsideClick && typeof onOutsideClick === "function"
          ? (e) => {
              e.preventDefault();
              onOutsideClick(e);
            }
          : undefined
      }
    >
      <div className={css.base}>
        {actionButtons
          ?.filter(
            (button) =>
              typeof button?.label === "string" && button.label.length > 0,
          )
          .map((actionButton, idx) => {
            if (!actionButton?.label) {
              return null;
            }

            return (
              <button
                className={css.actionButton({
                  action: actionButton.type ?? "default",
                })}
                key={idx}
                onClick={actionButton.onClick}
              >
                {actionButton.label}
              </button>
            );
          })}
        {dismissButton && (
          <button className={css.dismissButton} onClick={dismissButton.onClick}>
            {dismissButton.label}
          </button>
        )}
      </div>
    </BottomSheet>
  );
};
