import { IS_PROD } from "../../constants/environment";
import { BUSINESS_REVIEW_SERVER_URL } from "../../constants/serverURL";
import { applyAxiosInterceptors } from "../../lib/axios/axiosInstance";
import {
  __internalSetFetchConfig,
  createLike,
  deleteLike,
  deleteReview as _deleteReview,
} from "../__generated__/local-business-network/legacyReview";

const axiosInstance = __internalSetFetchConfig(
  IS_PROD ? "production" : "alpha",
  { host: BUSINESS_REVIEW_SERVER_URL, configs: { timeout: 5000 } },
);
applyAxiosInterceptors(axiosInstance);

/**
 * 후기 삭제
 */
export const deleteReview = (
  params: Parameters<typeof _deleteReview>["0"]["params"],
) => _deleteReview({ params });

/**
 * 후기 도움돼요 등록
 */
export const postReviewLike = (
  params: Parameters<typeof createLike>["0"]["params"],
) => createLike({ params });

/**
 * 후기 도움돼요 삭제
 */
export const deleteReviewLike = (
  params: Parameters<typeof deleteLike>["0"]["params"],
) => deleteLike({ params });
