/* eslint:disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ApiResponseObject } from '../../models';
import { LegacyReviewRequest } from '../../models';
import { ResponseLegacyReviewResponse } from '../../models';
import { ResponseSliceResponseLegacyReviewResponse } from '../../models';
import { ResponseVoid } from '../../models';
/**
 * LegacyBusinessReviewControllerApi - axios parameter creator
 * @export
 */

/**
 * createReview parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type CreateReviewParams = {
    legacyReviewRequest: LegacyReviewRequest;
    referrer?: string;
}
/**
 * 
 * @deprecated
 */
const createReviewAxiosParamCreator = async (
  // @ts-ignore
  params: CreateReviewParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/reviews`;
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['referrer'] !== undefined) {
          localVarQueryParameter['referrer'] = params['referrer'];
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = params['legacyReviewRequest'] || '';

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * deleteReview parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type DeleteReviewParams = {
    reviewId: string;
}
/**
 * 
 */
const deleteReviewAxiosParamCreator = async (
  // @ts-ignore
  params: DeleteReviewParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/reviews/{reviewId}`
    .replace(`{${"reviewId"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getReview parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetReviewParams = {
    reviewId: number;
}
/**
 * 
 */
const getReviewAxiosParamCreator = async (
  // @ts-ignore
  params: GetReviewParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/reviews/{reviewId}`
    .replace(`{${"reviewId"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getReviewsForUsers parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetReviewsForUsersParams = {
    userId: number;
    page?: number;
    lastId?: string;
    size?: number;
}
/**
 * 
 */
const getReviewsForUsersAxiosParamCreator = async (
  // @ts-ignore
  params: GetReviewsForUsersParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/users/{userId}/reviews`
    .replace(`{${"userId"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['lastId'] !== undefined) {
          localVarQueryParameter['last_id'] = params['lastId'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * updateReview parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type UpdateReviewParams = {
    reviewId: string;
    legacyReviewRequest: LegacyReviewRequest;
}
/**
 * 
 */
const updateReviewAxiosParamCreator = async (
  // @ts-ignore
  params: UpdateReviewParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/reviews/{reviewId}`
    .replace(`{${"reviewId"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'PUT', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = params['legacyReviewRequest'] || '';

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 
 * @deprecated
 */
const createReviewFp = async ({
  params,
  options,
}: {
  params: CreateReviewParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseLegacyReviewResponse>> => {
  const localVarAxiosArgs = await createReviewAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 
 */
const deleteReviewFp = async ({
  params,
  options,
}: {
  params: DeleteReviewParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseVoid>> => {
  const localVarAxiosArgs = await deleteReviewAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 
 */
const getReviewFp = async ({
  params,
  options,
}: {
  params: GetReviewParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseLegacyReviewResponse>> => {
  const localVarAxiosArgs = await getReviewAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 
 */
const getReviewsForUsersFp = async ({
  params,
  options,
}: {
  params: GetReviewsForUsersParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseSliceResponseLegacyReviewResponse>> => {
  const localVarAxiosArgs = await getReviewsForUsersAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 
 */
const updateReviewFp = async ({
  params,
  options,
}: {
  params: UpdateReviewParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseLegacyReviewResponse>> => {
  const localVarAxiosArgs = await updateReviewAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const createReview = ({
  params,
  options,
}: {
  params: CreateReviewParams;
  options?: AxiosRequestConfig,
}) => {
  return createReviewFp({ params, options, });
};
export const deleteReview = ({
  params,
  options,
}: {
  params: DeleteReviewParams;
  options?: AxiosRequestConfig,
}) => {
  return deleteReviewFp({ params, options, });
};
export const getReview = ({
  params,
  options,
}: {
  params: GetReviewParams;
  options?: AxiosRequestConfig,
}) => {
  return getReviewFp({ params, options, });
};
export const getReviewsForUsers = ({
  params,
  options,
}: {
  params: GetReviewsForUsersParams;
  options?: AxiosRequestConfig,
}) => {
  return getReviewsForUsersFp({ params, options, });
};
export const updateReview = ({
  params,
  options,
}: {
  params: UpdateReviewParams;
  options?: AxiosRequestConfig,
}) => {
  return updateReviewFp({ params, options, });
};

