import React from "react";

import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/routes/types";
import { type ActivityParams } from "./ActivitySocialCapitalSearchLegalRegion";

const ActivitySocialCapitalSearchLegalRegionLayout: ActivityLayoutComponentType<
  ActivityParams
> = ({ children }) => {
  return (
    <Screen
      appBar={{
        border: false,
        title: "당근을 모을 동네",
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivitySocialCapitalSearchLegalRegionLayout;
