import React from "react";

import { Screen } from "../shared/ui/screen/Screen";
import type { ActivityLayoutComponentType } from "../stackflow/routes/types";

const BaseActivityScreenNoBorderLayout: ActivityLayoutComponentType = ({
  children,
}) => {
  return <Screen appBar={{ border: false }}>{children}</Screen>;
};

export default BaseActivityScreenNoBorderLayout;
