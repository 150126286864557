import { useRedirectUserProfileEffect } from "../../../../features/user-profile/model/useRedirectUserProfileEffect";
import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/routes/types";

type PathParams = {
  /**
   * 프로필 ID
   */
  profile_id: string;
};
type QueryParams = {};
export type ActivityUserProfileReviewsParams = PathParams & QueryParams;

const ActivityLayoutUserProfileReviews: ActivityLayoutComponentType<
  ActivityUserProfileReviewsParams
> = ({ params, children }) => {
  useRedirectUserProfileEffect({
    activityName: "profile.local_profile_reviews",
    profileUserId: params.profile_id,
  });

  return <Screen appBar={{ title: "후기" }}>{children}</Screen>;
};

export default ActivityLayoutUserProfileReviews;
