/* eslint:disable */
/**
 * Business Review Service
 * Business Review Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ApiV2LocalProfilesLocalProfileIdReviewsGet200Response } from '../../models';
import { ApiV2ReviewsReviewIdGet200Response } from '../../models';
import { ApiV2ReviewsReviewIdPut200Response } from '../../models';
import { CreateReviewRequest } from '../../models';
import { OrderBy } from '../../models';
import { UpdateReviewRequest } from '../../models';
/**
 * BusinessReviewServiceApi - axios parameter creator
 * @export
 */

/**
 * apiV2LocalProfilesLocalProfileIdReviewsGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2LocalProfilesLocalProfileIdReviewsGetParams = {
    localProfileId: number;
    order?: OrderBy;
    filter?: Array<'EXCLUDE_COMMENTED_REVIEW'>;
    page?: number;
    size?: number;
    userId?: number;
}
/**
 * 로컬프로필 리뷰 리스트 조회
 * @summary 로컬프로필 리뷰 리스트 조회
 */
const apiV2LocalProfilesLocalProfileIdReviewsGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2LocalProfilesLocalProfileIdReviewsGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/local_profiles/{local_profile_id}/reviews`
    .replace(`{${"local_profile_id"}}`, encodeURIComponent(String(params["localProfileId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['order'] !== undefined) {
          localVarQueryParameter['order'] = params['order'];
      }

      if (params["filter"]) {
          localVarQueryParameter['filter'] = params['filter'];
      }

      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }

      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }

      if (params['userId'] !== undefined) {
          localVarQueryParameter['user_id'] = params['userId'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * apiV2ReviewsPost parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2ReviewsPostParams = {
    createReviewRequest: CreateReviewRequest;
}
/**
 * 리뷰 생성
 * @summary 리뷰 생성
 */
const apiV2ReviewsPostAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2ReviewsPostParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/reviews`;
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = params['createReviewRequest'] || '';

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * apiV2ReviewsReviewIdGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2ReviewsReviewIdGetParams = {
    reviewId: number;
    includeTagPreset?: boolean;
}
/**
 * 리뷰 단건 조회
 * @summary 리뷰 단건 조회
 */
const apiV2ReviewsReviewIdGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2ReviewsReviewIdGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/reviews/{review_id}`
    .replace(`{${"review_id"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['includeTagPreset'] !== undefined) {
          localVarQueryParameter['include_tag_preset'] = params['includeTagPreset'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * apiV2ReviewsReviewIdPut parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2ReviewsReviewIdPutParams = {
    reviewId: number;
    updateReviewRequest: UpdateReviewRequest;
}
/**
 * 리뷰 수정
 * @summary 리뷰 수정
 */
const apiV2ReviewsReviewIdPutAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2ReviewsReviewIdPutParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/reviews/{review_id}`
    .replace(`{${"review_id"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'PUT', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = params['updateReviewRequest'] || '';

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 로컬프로필 리뷰 리스트 조회
 * @summary 로컬프로필 리뷰 리스트 조회
 */
const apiV2LocalProfilesLocalProfileIdReviewsGetFp = async ({
  params,
  options,
}: {
  params: ApiV2LocalProfilesLocalProfileIdReviewsGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV2LocalProfilesLocalProfileIdReviewsGet200Response>> => {
  const localVarAxiosArgs = await apiV2LocalProfilesLocalProfileIdReviewsGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 리뷰 생성
 * @summary 리뷰 생성
 */
const apiV2ReviewsPostFp = async ({
  params,
  options,
}: {
  params: ApiV2ReviewsPostParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV2ReviewsReviewIdPut200Response>> => {
  const localVarAxiosArgs = await apiV2ReviewsPostAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 리뷰 단건 조회
 * @summary 리뷰 단건 조회
 */
const apiV2ReviewsReviewIdGetFp = async ({
  params,
  options,
}: {
  params: ApiV2ReviewsReviewIdGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV2ReviewsReviewIdGet200Response>> => {
  const localVarAxiosArgs = await apiV2ReviewsReviewIdGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 리뷰 수정
 * @summary 리뷰 수정
 */
const apiV2ReviewsReviewIdPutFp = async ({
  params,
  options,
}: {
  params: ApiV2ReviewsReviewIdPutParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV2ReviewsReviewIdPut200Response>> => {
  const localVarAxiosArgs = await apiV2ReviewsReviewIdPutAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const apiV2LocalProfilesLocalProfileIdReviewsGet = ({
  params,
  options,
}: {
  params: ApiV2LocalProfilesLocalProfileIdReviewsGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2LocalProfilesLocalProfileIdReviewsGetFp({ params, options, });
};
export const apiV2ReviewsPost = ({
  params,
  options,
}: {
  params: ApiV2ReviewsPostParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2ReviewsPostFp({ params, options, });
};
export const apiV2ReviewsReviewIdGet = ({
  params,
  options,
}: {
  params: ApiV2ReviewsReviewIdGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2ReviewsReviewIdGetFp({ params, options, });
};
export const apiV2ReviewsReviewIdPut = ({
  params,
  options,
}: {
  params: ApiV2ReviewsReviewIdPutParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2ReviewsReviewIdPutFp({ params, options, });
};

