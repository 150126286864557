/* eslint:disable */
/**
 * SMB Promotion Review Event Service
 * SMB Promotion Review Event API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ErrorResponse } from '../../models';
import { GetPaymentStatus200Response } from '../../models';
import { GetPaymentValidation200Response } from '../../models';
import { ResourceType } from '../../models';
/**
 * SMBPromotionReviewEventServiceApi - axios parameter creator
 * @export
 */

/**
 * getPaymentStatus parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetPaymentStatusParams = {
    reviewId: number;
}
/**
 * 소상공인의날 이벤트 당근머니 지급 여부 조회
 * @summary 소상공인의날 이벤트 당근머니 지급 여부 조회
 */
const getPaymentStatusAxiosParamCreator = async (
  // @ts-ignore
  params: GetPaymentStatusParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/smb_promotion_review_event/payment_status`;
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['reviewId'] !== undefined) {
          localVarQueryParameter['review_id'] = params['reviewId'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * getPaymentValidation parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetPaymentValidationParams = {
    localProfileId: number;
    resourceId: string;
    resourceType: ResourceType;
}
/**
 * 소상공인의날 이벤트 당근머니 지급 대상 여부 조회
 * @summary 소상공인의날 이벤트 당근머니 지급 대상 여부 조회
 */
const getPaymentValidationAxiosParamCreator = async (
  // @ts-ignore
  params: GetPaymentValidationParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/smb_promotion_review_event/payment_validation`;
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['localProfileId'] !== undefined) {
          localVarQueryParameter['local_profile_id'] = params['localProfileId'];
      }

      if (params['resourceId'] !== undefined) {
          localVarQueryParameter['resource_id'] = params['resourceId'];
      }

      if (params['resourceType'] !== undefined) {
          localVarQueryParameter['resource_type'] = params['resourceType'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 소상공인의날 이벤트 당근머니 지급 여부 조회
 * @summary 소상공인의날 이벤트 당근머니 지급 여부 조회
 */
const getPaymentStatusFp = async ({
  params,
  options,
}: {
  params: GetPaymentStatusParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetPaymentStatus200Response>> => {
  const localVarAxiosArgs = await getPaymentStatusAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 소상공인의날 이벤트 당근머니 지급 대상 여부 조회
 * @summary 소상공인의날 이벤트 당근머니 지급 대상 여부 조회
 */
const getPaymentValidationFp = async ({
  params,
  options,
}: {
  params: GetPaymentValidationParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<GetPaymentValidation200Response>> => {
  const localVarAxiosArgs = await getPaymentValidationAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const getPaymentStatus = ({
  params,
  options,
}: {
  params: GetPaymentStatusParams;
  options?: AxiosRequestConfig,
}) => {
  return getPaymentStatusFp({ params, options, });
};
export const getPaymentValidation = ({
  params,
  options,
}: {
  params: GetPaymentValidationParams;
  options?: AxiosRequestConfig,
}) => {
  return getPaymentValidationFp({ params, options, });
};

