import { Suspense } from "react";

import { Loading } from "../../shared/ui/loading/Loading";
import {
  type ActivityLayoutComponentType,
  type ActivityLazyComponentType,
  type ActivityUnLazyComponentType,
} from "./types";

const withActivityRenderer = <
  T extends { [K in keyof T]: string | undefined } = {},
>({
  layout: Layout,
  component: Component,
}: {
  component: ActivityLazyComponentType<T>;
  layout: ActivityLayoutComponentType<T>;
}) => {
  const WithActivityRenderer: ActivityUnLazyComponentType<T> = (props) => {
    return (
      <Layout {...props}>
        <Suspense fallback={<Loading />}>
          {Component && <Component {...props} />}
        </Suspense>
      </Layout>
    );
  };

  return WithActivityRenderer;
};

export default withActivityRenderer;
