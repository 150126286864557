import { vars } from "@seed-design/design-token";
import React from "react";

import AppBarAdditionalButton from "../../../../../../../entities/social-capital/ui/AppBarAdditionalButton";
import { DASHBOARD_BACKGROUND_COLOR } from "../../../../../../../features/social-captial-dashboard/model/constants";
import { Screen } from "../../../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../../../stackflow/routes/types";
import { type ActivityParams } from "./ActivitySocialCapitalRegionDashboard";

const ActivitySocialCapitalRegionDashboard: ActivityLayoutComponentType<
  ActivityParams
> = ({ children }) => {
  return (
    <Screen
      backgroundColor={DASHBOARD_BACKGROUND_COLOR}
      appBar={{
        iconColor: vars.$static.color.staticBlack,
        renderRight: () => (
          <AppBarAdditionalButton iconColor={vars.$static.color.staticBlack} />
        ),
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivitySocialCapitalRegionDashboard;
