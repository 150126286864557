/* eslint:disable */
/**
 * Region Service
 * Region Service Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ApiV1NeighborIdsByRangeGet200Response } from '../../models';
/**
 * RegionServiceApi - axios parameter creator
 * @export
 */

/**
 * apiV1NeighborIdsByRangeGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type ApiV1NeighborIdsByRangeGetParams = {
    regionId?: number;
    range?: 'NOPUBLISH' | 'MY' | 'ADJACENT' | 'RANGE2' | 'RANGE3';
}
/**
 * Retrieves neighboring region ids relative to region_id based on range
 * @summary Get neighbor ids by range
 */
const apiV1NeighborIdsByRangeGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV1NeighborIdsByRangeGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/neighbor_ids_by_range`;
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['regionId'] !== undefined) {
          localVarQueryParameter['region_id'] = params['regionId'];
      }

      if (params['range'] !== undefined) {
          localVarQueryParameter['range'] = params['range'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * Retrieves neighboring region ids relative to region_id based on range
 * @summary Get neighbor ids by range
 */
const apiV1NeighborIdsByRangeGetFp = async ({
  params,
  options,
}: {
  params: ApiV1NeighborIdsByRangeGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV1NeighborIdsByRangeGet200Response>> => {
  const localVarAxiosArgs = await apiV1NeighborIdsByRangeGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const apiV1NeighborIdsByRangeGet = ({
  params,
  options,
}: {
  params: ApiV1NeighborIdsByRangeGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV1NeighborIdsByRangeGetFp({ params, options, });
};

