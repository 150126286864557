import { IconHelpRegular } from "@seed-design/icon";
import React from "react";

import { Screen } from "../../../../../shared/ui/screen/Screen";
import type { ActivityLayoutComponentType } from "../../../../../stackflow/routes/types";
import { useNavigator } from "../../../../../stackflow/useNavigator";

const ActivityLocalProfileSuggestionCreateLandingLayout: ActivityLayoutComponentType =
  ({ children }) => {
    const { push } = useNavigator();
    const onClickHelp = () => {
      push("social_capital.point_system", {});
    };

    return (
      <Screen
        appBar={{
          renderRight: () => (
            <IconHelpRegular
              width={24}
              height={24}
              onClick={onClickHelp}
              style={{
                marginRight: "0.5rem",
              }}
            />
          ),
        }}
      >
        {children}
      </Screen>
    );
  };

export default ActivityLocalProfileSuggestionCreateLandingLayout;
