import { IS_PROD } from "../../constants/environment";
import { BUSINESS_REVIEW_SERVER_URL } from "../../constants/serverURL";
import { applyAxiosInterceptors } from "../../lib/axios/axiosInstance";
import {
  __internalSetFetchConfig,
  apiExperimentalBusinessAccountsBusinessAccountIdInterestedUsersGet,
  apiExperimentalReviewableBusinessAccountsPersonalizedGet,
  apiExperimentalReviewableBusinessAccountsPopularGet,
} from "../__generated__/local-business-network/reviewExperimental";

const axiosInstance = __internalSetFetchConfig(
  IS_PROD ? "production" : "alpha",
  { host: BUSINESS_REVIEW_SERVER_URL, configs: { timeout: 5000 } },
);
applyAxiosInterceptors(axiosInstance);

/**
 * 개인화된 리뷰 가능한 비즈프로필 목록 조회
 */
export const getPersonalizedReviewableBusinessAccounts = (
  params: Parameters<
    typeof apiExperimentalReviewableBusinessAccountsPersonalizedGet
  >["0"]["params"],
) => apiExperimentalReviewableBusinessAccountsPersonalizedGet({ params });

/**
 * 인기있는 리뷰 가능한 비즈프로필 목록 조회
 */
export const getPopularReviewableBusinessAccounts = (
  params: Parameters<
    typeof apiExperimentalReviewableBusinessAccountsPopularGet
  >["0"]["params"],
) => apiExperimentalReviewableBusinessAccountsPopularGet({ params });

/**
 * 비즈프로필에 관심있는 유저 목록 조회
 */
export const getUsersInterestedInBusinessAccount = (
  params: Parameters<
    typeof apiExperimentalBusinessAccountsBusinessAccountIdInterestedUsersGet
  >["0"]["params"],
) =>
  apiExperimentalBusinessAccountsBusinessAccountIdInterestedUsersGet({
    params,
  });
