import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteReview } from "../../../shared/api/business-review";
import { queryKeys } from "../../../shared/lib/react-query/queryKey";

type DeleteReviewParams = {
  reviewId: string;
};

export const useDeleteReview = ({ reviewId }: DeleteReviewParams) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["delete-review"],
    mutationFn: async () => {
      await deleteReview({ reviewId });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.review.lists()],
      });
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.review.detail(+reviewId)],
      });
    },
  });

  return mutation;
};
