import { Screen } from "../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../stackflow/routes/types";
import { CreateReviewErrorBoundary } from "./CreateReviewErrorBoundary";

type PathParams = {};

/**
 * @deprecated
 */
type QueryParamsV1 = {
  /**
   * 후기 타입
   */
  type?:
    | "BUSINESS_ACCOUNT"
    | "COMMERCE_ORDER"
    | "LIFE_SERVICE_QUEST"
    | "POI"
    | "RESERVATION_V1"
    | "RESERVATION_V2";
  /**
   * 태그 그룹 아이디 (https://ba-admin.alpha.kr.wekarrot.net/integrated-reviews/tag-groups)
   */
  tag_group_id: string;
  /**
   * star-rating range between 1-5
   */
  star_rating?: string;
  /**
   * POI 아이디
   */
  poi_id?: string;
  /**
   * 비즈프로필 아이디
   */
  business_account_id?: string;
  /**
   * 커머스 주문 아이디
   */
  commerce_order_id?: string;
  /**
   * 예약 아이디
   */
  reservation_id?: string;
  /**
   * 생활서비스 견적서 아이디
   */
  life_service_quest_id?: string;
};

type QueryParamsV2 = {
  /**
   * 로컬프로필 아이디
   */
  local_profile_id: string;
  /**
   * 후기 타입
   */
  resource_type:
    | "LOCAL_PROFILE"
    | "COMMERCE_ORDER"
    | "LIFE_SERVICE_QUEST"
    | "RESERVATION_V1"
    | "RESERVATION_V2";
  /**
   * 후기 타입에 따른 리소스 아이디
   */
  resource_id: string;
  /**
   * 태그 그룹 아이디 (https://ba-admin.alpha.kr.wekarrot.net/integrated-reviews/tag-groups)
   */
  tag_group_id: string;
  /**
   * star-rating range between 1-5
   */
  star_rating?: string;
};
type PrivateQueryParams = {
  dialog?: "pop-block";
};

export type ActivityCreateReviewParams = PathParams &
  QueryParamsV1 &
  QueryParamsV2 &
  PrivateQueryParams;

const ActivityCreateReviewLayout: ActivityLayoutComponentType<
  ActivityCreateReviewParams
> = ({ children }) => {
  return (
    <Screen appBar={{ title: "후기 작성" }}>
      <CreateReviewErrorBoundary>{children}</CreateReviewErrorBoundary>
    </Screen>
  );
};

export default ActivityCreateReviewLayout;
