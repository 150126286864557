import { useSuspenseQuery } from "@tanstack/react-query";

import { getRemoteConfig } from "../api/business-gateway";

export const useSuspenseQueryRemoteConfig = () => {
  return useSuspenseQuery({
    queryKey: ["remote-config"],
    queryFn: async () => {
      const response = await getRemoteConfig({});

      return response.data;
    },
  });
};
