import { AlertDialog, DialogContainer } from "@daangn/sprout-components-dialog";
import { useSnackbarAdapter } from "@daangn/sprout-components-snackbar";
import { type ActivityComponentType } from "@stackflow/react";
import { useState } from "react";

import { useDeleteReview } from "../../../../entities/review/queries/useDeleteReview";
import { SNACKBAR_DURATION } from "../../../../shared/constants/duration";
import { ActionSheet } from "../../../../shared/ui/action-sheet/ActionSheet";
import { useNavigator } from "../../../../stackflow/useNavigator";

type PathParams = {
  /**
   * 후기 ID
   */
  review_id: string;
};
type QueryParams = {
  /**
   * @todo Implement type casting to ActivityComponentType to receive types other than string | undefined
   */
  local_profile_deleted: "true" | "false";
};
export type ActivityReviewActionSheetParams = PathParams & QueryParams;
const ActivityReviewActionSheet: ActivityComponentType<
  ActivityReviewActionSheetParams
> = ({ params: { review_id, local_profile_deleted } }) => {
  const { replace, pop } = useNavigator();
  const snackbarAdapter = useSnackbarAdapter();
  const { mutateAsync: deleteReview } = useDeleteReview({
    reviewId: review_id,
  });

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const handleDeleteReview = async () => {
    await deleteReview();

    snackbarAdapter.create({
      message: "후기를 삭제했어요.", // Snackbar에 표시할 메시지
      type: "default", // Snackbar의 타입.
      timeout: SNACKBAR_DURATION.SHORT, // Snackbar가 자동으로 닫히는 시간.
      removeDelay: 500, // Snackbar가 timeout 이후 실제로 DOM에서 제거되는 시간.
    });

    setOpenDeleteAlert(false);
    pop();
  };

  return (
    <>
      <ActionSheet
        actionButtons={[
          local_profile_deleted === "true"
            ? undefined
            : {
                label: "수정",
                type: "default",
                onClick: () =>
                  replace(
                    "review.update",
                    { review_id },
                    { intermediate: true },
                  ),
              },
          {
            label: "삭제",
            type: "destructive",
            onClick: () => setOpenDeleteAlert(true),
          },
        ]}
      />

      {openDeleteAlert && (
        <DialogContainer>
          <AlertDialog
            description="후기를 삭제할까요?"
            primaryActionLabel="삭제"
            secondaryActionLabel="취소"
            secondaryActionIntent="neutral"
            onPrimaryAction={() => {
              handleDeleteReview();
            }}
            onSecondaryAction={() => setOpenDeleteAlert(false)}
          />
        </DialogContainer>
      )}
    </>
  );
};

export default ActivityReviewActionSheet;
