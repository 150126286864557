import { IS_PROD, USE_MOCK } from "../../constants/environment";
import { BUSINESS_REVIEW_SERVER_URL } from "../../constants/serverURL";
import { applyAxiosInterceptors } from "../../lib/axios/axiosInstance";
import {
  __internalSetFetchConfig,
  apiV2ReviewsPost,
  apiV2ReviewsReviewIdGet,
  apiV2ReviewsReviewIdPut,
  apiV2UsersUserIdIsReviewableGet,
  apiV2UsersUserIdRankedLocalProfilesGet,
  apiV2UsersUserIdReviewsGet,
  type CommerceOrderExtendResponse,
  getPaymentStatus,
  getPaymentValidation,
  type LifeServiceExtendResponse,
  type LocalProfileExtendResponse,
  type ReservationV1ExtendResponse,
  type ReservationV2ExtendResponse,
  type ResourceType,
  type ReviewResponse,
} from "../__generated__/local-business-network/reviewV2";
import {
  GET_SMALL_BUSINESS_DAY_EVENT_MONEY_PAYMENT_STATUS_MOCK_RESPONSE,
  GET_SMALL_BUSINESS_DAY_EVENT_MONEY_PAYMENT_VALIDATION_MOCK_RESPONSE,
} from "./mockData";
const axiosInstance = __internalSetFetchConfig(
  IS_PROD ? "production" : "alpha",
  { host: BUSINESS_REVIEW_SERVER_URL, configs: { timeout: 5000 } },
);
applyAxiosInterceptors(axiosInstance);

/**
 * 유저 리뷰 목록 조회
 */
export const getUserReviews = (
  params: Parameters<typeof apiV2UsersUserIdReviewsGet>["0"]["params"],
) => apiV2UsersUserIdReviewsGet({ params });

/**
 * 유저의 리뷰 가능 여부 조회
 */
export const getIsReviewable = (
  params: Parameters<typeof apiV2UsersUserIdIsReviewableGet>["0"]["params"],
) => apiV2UsersUserIdIsReviewableGet({ params });

/**
 * 후기 생성
 */
export const postReview = (
  params: Parameters<typeof apiV2ReviewsPost>["0"]["params"],
) => apiV2ReviewsPost({ params });

/**
 * 후기 수정
 */
export const putReview = (
  params: Parameters<typeof apiV2ReviewsReviewIdPut>["0"]["params"],
) => apiV2ReviewsReviewIdPut({ params });

/**
 * 후기 단건 조회
 */
export const getReview = (
  params: Parameters<typeof apiV2ReviewsReviewIdGet>["0"]["params"],
) => apiV2ReviewsReviewIdGet({ params });

/**
 * 유저에게 추천할 로컬프로필 목록 조회
 */
export const getRankedLocalProfiles = (
  params: Parameters<
    typeof apiV2UsersUserIdRankedLocalProfilesGet
  >["0"]["params"],
) => apiV2UsersUserIdRankedLocalProfilesGet({ params });

export type Review = ReviewResponse;
export type ReviewExtend =
  | ({ resource_type: "LOCAL_PROFILE" } & LocalProfileExtendResponse)
  | ({ resource_type: "COMMERCE_ORDER" } & CommerceOrderExtendResponse)
  | ({ resource_type: "LIFE_SERVICE_QUEST" } & LifeServiceExtendResponse)
  | ({ resource_type: "RESERVATION_V1" } & ReservationV1ExtendResponse)
  | ({ resource_type: "RESERVATION_V2" } & ReservationV2ExtendResponse);
export type ReviewResourceType = ResourceType;

// 소상공인의 날 이벤트에서 사용하는 API
/**
 * 소상공인의날 이벤트 당근머니 지급 대상 여부 조회
 */
export const getSmallBusinessDayEventMoneyPaymentValidation = (
  params: Parameters<typeof getPaymentValidation>["0"]["params"],
): ReturnType<typeof getPaymentValidation> => {
  if (USE_MOCK) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          GET_SMALL_BUSINESS_DAY_EVENT_MONEY_PAYMENT_VALIDATION_MOCK_RESPONSE,
        );
      }, 500);
    });
  }

  return getPaymentValidation({ params });
};
/**
 * 소상공인의날 이벤트 당근머니 지급 여부 조회
 */
export const getSmallBusinessDayEventMoneyPaymentStatus = (
  params: Parameters<typeof getPaymentStatus>["0"]["params"],
): ReturnType<typeof getPaymentStatus> => {
  if (USE_MOCK) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          GET_SMALL_BUSINESS_DAY_EVENT_MONEY_PAYMENT_STATUS_MOCK_RESPONSE,
        );
      }, 500);
    });
  }

  return getPaymentStatus({ params });
};
//
