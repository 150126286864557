/**
 * Custom User-Agent Spec.
 * https://www.notion.so/daangn/Custom-User-Agent-20c6ccb681104319b39a006dba4acd00
 *
 * iOS
 * - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.22.0 (242200; iOS 17.5.1; Alpha; debug)
 *
 * Android
 * - Mozilla/5.0 (Linux; Android 12; SM-N971N Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.71 Mobile Safari/537.36 TowneersApp/24.26.1 (242601; Android 12; alpha; debug)
 */
const USER_AGENT_REGEX =
  /(?<customUserAgent>TowneersApp\/(?<versionName>\d+\.\d+\.\d+)\s\((?<versionCode>\d+);\s*(?<osType>iOS|Android)\s(?<osVersion>[^;]+);\s*(?<appType>\w+);\s*(?<buildType>\w+)\))/;

const BUILD_TYPES = ["debug", "release"] as const;

type BuildTypes = (typeof BUILD_TYPES)[number];

const extractAppBuildType = (userAgent: string) => {
  const result = userAgent.match(USER_AGENT_REGEX);

  if (!result || !result.groups) {
    return null;
  }

  const { buildType } = result.groups;

  if (!BUILD_TYPES.some((type) => type === buildType)) {
    return null;
  }

  return buildType as BuildTypes;
};

/**
 * debug - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.22.0 (242200; iOS 17.5.1; Alpha; debug)
 * release - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.26.0 (242600; iOS 17.5.1; Production; release)
 */
export const IS_DEBUG_APP =
  extractAppBuildType(window.navigator.userAgent) === "debug";
