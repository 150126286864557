/* eslint:disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ApiResponseObject } from '../../models';
import { ResponseObject } from '../../models';
/**
 * LegacyLikeControllerApi - axios parameter creator
 * @export
 */

/**
 * createLike parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type CreateLikeParams = {
    reviewId: string;
}
/**
 * 
 */
const createLikeAxiosParamCreator = async (
  // @ts-ignore
  params: CreateLikeParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/reviews/{reviewId}/likes`
    .replace(`{${"reviewId"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * deleteLike parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type DeleteLikeParams = {
    reviewId: string;
}
/**
 * 
 */
const deleteLikeAxiosParamCreator = async (
  // @ts-ignore
  params: DeleteLikeParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/legacy/reviews/{reviewId}/likes`
    .replace(`{${"reviewId"}}`, encodeURIComponent(String(params["reviewId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 
 */
const createLikeFp = async ({
  params,
  options,
}: {
  params: CreateLikeParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseObject>> => {
  const localVarAxiosArgs = await createLikeAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 
 */
const deleteLikeFp = async ({
  params,
  options,
}: {
  params: DeleteLikeParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ResponseObject>> => {
  const localVarAxiosArgs = await deleteLikeAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const createLike = ({
  params,
  options,
}: {
  params: CreateLikeParams;
  options?: AxiosRequestConfig,
}) => {
  return createLikeFp({ params, options, });
};
export const deleteLike = ({
  params,
  options,
}: {
  params: DeleteLikeParams;
  options?: AxiosRequestConfig,
}) => {
  return deleteLikeFp({ params, options, });
};

