import { Screen } from "../../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../../stackflow/routes/types";

type PathParams = {
  /**
   * 후기 ID
   */
  review_id: string;
};

export type ActivitySmallBusinessDayEventReviewQualityScoringParams =
  PathParams;

const ActivitySmallBusinessDayEventReviewQualityScoringLayout: ActivityLayoutComponentType<
  ActivitySmallBusinessDayEventReviewQualityScoringParams
> = ({ children }) => {
  return <Screen>{children}</Screen>;
};

export default ActivitySmallBusinessDayEventReviewQualityScoringLayout;
