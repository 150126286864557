/* eslint:disable */
/**
 * Business Review Tags Service
 * Business Review Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ApiV1TagGroupsTagGroupIdTagsGet200Response } from '../../models';
/**
 * BusinessReviewTagServiceApi - axios parameter creator
 * @export
 */

/**
 * apiV1TagGroupsTagGroupIdTagsGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type ApiV1TagGroupsTagGroupIdTagsGetParams = {
    tagGroupId: number;
}
/**
 * 태그 그룹 아이디로 태그 목록 조회
 * @summary 태그 그룹 아이디로 태그 목록 조회
 */
const apiV1TagGroupsTagGroupIdTagsGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV1TagGroupsTagGroupIdTagsGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v1/tag_groups/{tag_group_id}/tags`
    .replace(`{${"tag_group_id"}}`, encodeURIComponent(String(params["tagGroupId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 태그 그룹 아이디로 태그 목록 조회
 * @summary 태그 그룹 아이디로 태그 목록 조회
 */
const apiV1TagGroupsTagGroupIdTagsGetFp = async ({
  params,
  options,
}: {
  params: ApiV1TagGroupsTagGroupIdTagsGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV1TagGroupsTagGroupIdTagsGet200Response>> => {
  const localVarAxiosArgs = await apiV1TagGroupsTagGroupIdTagsGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const apiV1TagGroupsTagGroupIdTagsGet = ({
  params,
  options,
}: {
  params: ApiV1TagGroupsTagGroupIdTagsGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV1TagGroupsTagGroupIdTagsGetFp({ params, options, });
};

