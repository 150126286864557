import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import AppBarSocialCapitalPointCollectionGuideButton from "../../../../../entities/social-capital/ui/AppBarSocialCapitalPointCollectionGuideButton";
import { Screen } from "../../../../../shared/ui/screen/Screen";
import type { ActivityLayoutComponentType } from "../../../../../stackflow/routes/types";
import { useNavigator } from "../../../../../stackflow/useNavigator";
import { MaintenanceModeSocialCapitalBoundary } from "../../../../../widgets/social-capital/MaintenanceModeSocialCapitalBoundary";
import { type ActivityParams } from "./ActivitySocialCapitalContributeSuggestionLanding";

const ActivitySocialCapitalContributeSuggestionLandingLayout: ActivityLayoutComponentType<
  ActivityParams
> = ({ children, params }) => {
  const { replace } = useNavigator();

  const handleOnReplaceActivity = () => {
    replace(
      "local_profile_suggestion.landing",
      { local_profile_suggestion_id: params.local_profile_suggestion_id },
      { animate: false },
    );
  };

  return (
    <Screen
      appBar={{
        renderRight: () => <AppBarSocialCapitalPointCollectionGuideButton />,
      }}
    >
      <ErrorBoundary
        fallbackRender={() => {
          return null;
        }}
        onError={handleOnReplaceActivity}
      >
        <MaintenanceModeSocialCapitalBoundary
          fallback={null}
          onMaintenanceMode={handleOnReplaceActivity}
        >
          {children}
        </MaintenanceModeSocialCapitalBoundary>
      </ErrorBoundary>
    </Screen>
  );
};

export default ActivitySocialCapitalContributeSuggestionLandingLayout;
