import { logEvent } from "@daangn/local-business-analytics";
import { karrotAnalyticsPlugin } from "@daangn/stackflow-plugin-karrot-analytics";
import { vars } from "@seed-design/design-token";
import { basicUIPlugin, IconBack } from "@stackflow/plugin-basic-ui";
import { devtoolsPlugin } from "@stackflow/plugin-devtools";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { stackDepthChangePlugin } from "@stackflow/plugin-stack-depth-change";
import { stackflow } from "@stackflow/react";

import { STACKFLOW_TRANSITION_DURATION } from "../shared/constants/duration";
import {
  IS_PROD,
  LOCAL_BUSINESS_UGC_SERVICE_NAME,
  THEME,
} from "../shared/constants/environment";
import { bridge } from "../shared/lib/karrot-bridge/bridge";
import { localBusinessAnalyticsStackflowPlugin } from "../shared/lib/local-business-analytics/localBusinessAnalytics";
import { getQueryParam } from "../shared/utils/url/getQueryParam";
import { disableSocialCapitalPlugin } from "./plugins/disableSocialCapitalPlugin";
import { maintenanceModeSocialCapitalPlugin } from "./plugins/maintenanceModeSocialCapitalPlugin";
import { routeMap } from "./routes/routes";
import {
  type ActivityLayoutComponentType,
  type ExtractLazyComponentParams,
} from "./routes/types";
import withActivityRenderer from "./routes/withActivityRenderer";

const routes = Object.entries(routeMap).reduce(
  (prev, [key, { path }]) => {
    return {
      ...prev,
      [key]: path,
    };
  },
  {} as {
    [K in keyof typeof routeMap]: (typeof routeMap)[K]["path"];
  },
);

const activities = Object.entries(routeMap).reduce(
  (prev, [key, route]) => {
    return {
      ...prev,
      [key]: withActivityRenderer({
        layout: route.layout as ActivityLayoutComponentType<
          ExtractLazyComponentParams<typeof route.layout>
        >,
        component: route.component as ActivityLayoutComponentType<
          ExtractLazyComponentParams<typeof route.component>
        >,
      }),
    };
  },
  {} as {
    [P in keyof typeof routeMap]: (typeof routeMap)[P]["component"];
  },
);

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */

const {
  actions,
  Stack,
  useFlow,
  useStepFlow,
  activities: _activities,
} = stackflow({
  transitionDuration: STACKFLOW_TRANSITION_DURATION,
  activities,

  plugins: [
    historySyncPlugin({
      urlPatternOptions: {
        segmentNameCharset: "a-zA-Z0-9_-",
      },
      routes,
      fallbackActivity: () => "error.not_found",
    }),
    basicRendererPlugin(),
    basicUIPlugin({
      theme: THEME,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor:
          THEME === "cupertino"
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        closeButton: {
          renderIcon: () => <IconBack />,
          onClick() {
            bridge.closeRouter({});
          },
        },
      },
    }),
    stackDepthChangePlugin({
      onInit: () => {
        bridge.styleCurrentRouter({
          router: {
            navbar: false,
            scrollable: true,
            backSwipable: true,
          },
        });
      },
      onDepthChanged: ({ depth }) => {
        bridge.styleCurrentRouter({
          router: {
            navbar: false,
            scrollable: true,
            backSwipable: depth === 0,
          },
        });
      },
    }),
    karrotAnalyticsPlugin({
      bridge: bridge,
      serviceName: LOCAL_BUSINESS_UGC_SERVICE_NAME,
    }),
    localBusinessAnalyticsStackflowPlugin({
      debug: !IS_PROD,
      logEvent: ({ name, params }) =>
        logEvent({ bridge: { type: "KARROT_BRIDGE", bridge } })({
          name,
          params: {
            ...params,
            where: `${LOCAL_BUSINESS_UGC_SERVICE_NAME}.${params.where}`,
          },
        }),
      commonPayloads: {
        referrer: getQueryParam("referrer"),
      },
    }),
    !IS_PROD ? devtoolsPlugin() : [],
    /* CBT기간(8/29~9/12) 동안 사내구성원 에게만 소셜캐피탈 기능을 노출합니다. */
    disableSocialCapitalPlugin(),
    /* 소셜 캐피탈, 점검 여부 feature flag 를 통해 기존 페이지를 점검중 페이지로 노출해요.   */
    maintenanceModeSocialCapitalPlugin(),
  ],
});

export { actions, Stack };
export type TypeUseFlow = typeof useFlow;
export type TypeUseStepFlow = typeof useStepFlow;
export type Activities = typeof _activities;
export { useStepFlow };
