import { vars } from "@seed-design/design-token";
import { IconHelpRegular } from "@seed-design/icon";
import React from "react";

import { useNavigator } from "../../../stackflow/useNavigator";
import * as css from "./AppBarSocialCapitalPointCollectionGuideButton.css";

const AppBarSocialCapitalPointCollectionGuideButton = () => {
  const { push } = useNavigator();

  const handleOnClickPointGuide = () => {
    push("social_capital.point_system", {});
  };

  return (
    <div className={css.base}>
      <button className={css.button} onClick={handleOnClickPointGuide}>
        <IconHelpRegular
          width="100%"
          height="100%"
          color={vars.$scale.color.gray900}
        />
      </button>
    </div>
  );
};

export default AppBarSocialCapitalPointCollectionGuideButton;
