import { routeMap } from "./routes";

// https://github.com/facebook/react/blob/9defcd56bc3cd53ac2901ed93f29218007010434/packages/react/src/ReactLazy.js
export const preloadComponent = (activityName: keyof typeof routeMap) => {
  const unTypedComponent = routeMap[activityName].component;

  try {
    if (
      "_init" in unTypedComponent &&
      typeof unTypedComponent._init === "function" &&
      "_payload" in unTypedComponent &&
      (unTypedComponent as any)._payload?._status !== 1
    ) {
      unTypedComponent._init(unTypedComponent._payload)?.catch?.(() => null);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
