/**
 * 참고:
 * 공용으로 사용되는 CSS를 index.ts에 포함시켜서,
 * Code Splitting으로 인한 과도한 번들 사이즈 증가를 막습니다.
 */
import "normalize.css";
import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "@daangn/sprout-components-checkbox/index.css";
import "@daangn/sprout-components-button/index.css";
import "@daangn/sprout-components-spinner/index.css";
import "@daangn/sprout-components-text-field/index.css";
import "@daangn/sprout-components-avatar/index.css";
import "@daangn/sprout-components-callout/index.css";
import "@daangn/sprout-components-dialog/index.css";
import "@daangn/sprout-components-snackbar/index.css";
import "@daangn/sprout-components-divider/index.css";
import "@daangn/business-review-ui/index.css";
import "@daangn/ugc-social-capital-ui/index.css";
import "@daangn/sprout-components-radio-group/index.css";
import "./shared/styles";

import { createRoot } from "react-dom/client";

import App from "./app/App";
import { checkInvalidReferrer } from "./app/checkInvalidReferrer";
import { IS_DEBUG_APP } from "./entities/karrot";
import { createExternalPromiseBridgeInfo } from "./features/karrot-bridge/model/createExternalPromiseBridgeInfo";
import { IS_DEV, IS_PROD, STAGE } from "./shared/constants/environment";
import { datadogRUM } from "./shared/lib/datadog/datadog";
import { sentry } from "./shared/lib/sentry/sentry";
import { getVConsole } from "./shared/lib/v-console/vconsole";

if (STAGE === "production" || STAGE === "alpha") {
  // Sentry 초기화
  sentry.initialize();
  // Datadog RUM 초기화
  datadogRUM.initialize();
}

if (IS_DEBUG_APP || IS_DEV) {
  getVConsole();
}

/**
 * Invalid Referrer를 체크합니다. (alpha 환경에서만)
 */
if (!IS_PROD && STAGE === "alpha") {
  checkInvalidReferrer();
}

createExternalPromiseBridgeInfo();

/**
 * React 18와 Concurrent Rendering을 사용합니다.
 * Stackflow는 startTransition을 적극적으로 활용하고 있습니다.
 * Preloading 등의 테크닉을 통해 JavaScript 코드와 API 콜을 동시에 수행하세요.
 */
const root = createRoot(document.getElementById("root")!);
root.render(<App />);
