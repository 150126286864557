import { BottomSheet } from "@stackflow/plugin-basic-ui";
import React from "react";

import type { ActivityLayoutComponentType } from "../stackflow/routes/types";

const BaseActivityBottomSheetLayout: ActivityLayoutComponentType = ({
  children,
}) => {
  return <BottomSheet>{children}</BottomSheet>;
};

export default BaseActivityBottomSheetLayout;
