import { IS_PROD } from "../../constants/environment";
import { BUSINESS_REVIEW_SERVER_URL } from "../../constants/serverURL";
import { applyAxiosInterceptors } from "../../lib/axios/axiosInstance";
import {
  __internalSetFetchConfig as __internalSetFetchConfigV1,
  apiV1NeighborIdsByRangeGet,
  apiV1TagGroupsTagGroupIdTagsGet,
  apiV1UsersUserIdProfilesGet,
} from "../__generated__/local-business-network/reviewV1";

const axiosInstance = __internalSetFetchConfigV1(
  IS_PROD ? "production" : "alpha",
  { host: BUSINESS_REVIEW_SERVER_URL, configs: { timeout: 5000 } },
);
applyAxiosInterceptors(axiosInstance);

/**
 * 유저 프로필 조회
 */
export const getUserProfile = (
  params: Parameters<typeof apiV1UsersUserIdProfilesGet>["0"]["params"],
) => apiV1UsersUserIdProfilesGet({ params });

/**
 * 리뷰 태그 목록 조회
 */
export const getReviewTags = (
  params: Parameters<typeof apiV1TagGroupsTagGroupIdTagsGet>["0"]["params"],
) => apiV1TagGroupsTagGroupIdTagsGet({ params });

/**
 * 인접 지역 조회
 */
export const getNeighborRegionIds = (
  params: Parameters<typeof apiV1NeighborIdsByRangeGet>["0"]["params"],
) => apiV1NeighborIdsByRangeGet({ params });
