/* eslint:disable */
/**
 * Business Review User Service
 * Business Review Rest API Spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosRequestConfig, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../../configs/common';

import { ApiV2UsersUserIdRankedLocalProfilesGet200Response } from '../../models';
import { ApiV2UsersUserIdReviewsGet200Response } from '../../models';
import { ResourceType } from '../../models';
import { ReviewableResponse } from '../../models';
/**
 * BusinessReviewUserServiceApi - axios parameter creator
 * @export
 */

/**
 * apiV2UsersUserIdIsReviewableGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2UsersUserIdIsReviewableGetParams = {
    userId: number;
    resourceType: ResourceType;
    resourceId: string;
    localProfileId: number;
}
/**
 * 리뷰 작성 가능 여부 조회
 * @summary 리뷰 작성 가능 여부 조회
 */
const apiV2UsersUserIdIsReviewableGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2UsersUserIdIsReviewableGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/users/{user_id}/is_reviewable`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['resourceType'] !== undefined) {
          localVarQueryParameter['resource_type'] = params['resourceType'];
      }

      if (params['resourceId'] !== undefined) {
          localVarQueryParameter['resource_id'] = params['resourceId'];
      }

      if (params['localProfileId'] !== undefined) {
          localVarQueryParameter['local_profile_id'] = params['localProfileId'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * apiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPost parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostParams = {
    userId: number;
    localProfileId: number;
}
/**
 * Send impression event for nudged local profile
 * @summary Send impression event for nudged local profile
 */
const apiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/users/{user_id}/nudged_local_profiles/{local_profile_id}/impression`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])))
    .replace(`{${"local_profile_id"}}`, encodeURIComponent(String(params["localProfileId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * apiV2UsersUserIdRankedLocalProfilesGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2UsersUserIdRankedLocalProfilesGetParams = {
    userId: number;
    limit?: number;
}
/**
 * Returns a list of local profiles that can be reviewed by the user. This list is personalized for the user.
 * @summary Get reviewable local profiles ranked by user personalization
 */
const apiV2UsersUserIdRankedLocalProfilesGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2UsersUserIdRankedLocalProfilesGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/users/{user_id}/ranked_local_profiles`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['limit'] !== undefined) {
          localVarQueryParameter['limit'] = params['limit'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };
/**
 * apiV2UsersUserIdReviewsGet parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiV2UsersUserIdReviewsGetParams = {
    userId: number;
    size?: number;
    page?: number;
}
/**
 * 중고거래 유저 아이디로 작성한 모든 리뷰를 조회해요.
 * @summary Retrieve user\'s reviews
 */
const apiV2UsersUserIdReviewsGetAxiosParamCreator = async (
  // @ts-ignore
  params: ApiV2UsersUserIdReviewsGetParams,
  options: AxiosRequestConfig = {},
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/users/{user_id}/reviews`
    .replace(`{${"user_id"}}`, encodeURIComponent(String(params["userId"])));
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL);

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options};
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
      if (params['size'] !== undefined) {
          localVarQueryParameter['size'] = params['size'];
      }

      if (params['page'] !== undefined) {
          localVarQueryParameter['page'] = params['page'];
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
  };

/**
 * 리뷰 작성 가능 여부 조회
 * @summary 리뷰 작성 가능 여부 조회
 */
const apiV2UsersUserIdIsReviewableGetFp = async ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdIsReviewableGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ReviewableResponse>> => {
  const localVarAxiosArgs = await apiV2UsersUserIdIsReviewableGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * Send impression event for nudged local profile
 * @summary Send impression event for nudged local profile
 */
const apiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostFp = async ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<void>> => {
  const localVarAxiosArgs = await apiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * Returns a list of local profiles that can be reviewed by the user. This list is personalized for the user.
 * @summary Get reviewable local profiles ranked by user personalization
 */
const apiV2UsersUserIdRankedLocalProfilesGetFp = async ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdRankedLocalProfilesGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV2UsersUserIdRankedLocalProfilesGet200Response>> => {
  const localVarAxiosArgs = await apiV2UsersUserIdRankedLocalProfilesGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}
/**
 * 중고거래 유저 아이디로 작성한 모든 리뷰를 조회해요.
 * @summary Retrieve user\'s reviews
 */
const apiV2UsersUserIdReviewsGetFp = async ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdReviewsGetParams,
  options?: AxiosRequestConfig,
}): Promise<AxiosResponse<ApiV2UsersUserIdReviewsGet200Response>> => {
  const localVarAxiosArgs = await apiV2UsersUserIdReviewsGetAxiosParamCreator(params, options);

  return createRequest({ axiosArgs: localVarAxiosArgs });
}

export const apiV2UsersUserIdIsReviewableGet = ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdIsReviewableGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2UsersUserIdIsReviewableGetFp({ params, options, });
};
export const apiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPost = ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2UsersUserIdNudgedLocalProfilesLocalProfileIdImpressionPostFp({ params, options, });
};
export const apiV2UsersUserIdRankedLocalProfilesGet = ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdRankedLocalProfilesGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2UsersUserIdRankedLocalProfilesGetFp({ params, options, });
};
export const apiV2UsersUserIdReviewsGet = ({
  params,
  options,
}: {
  params: ApiV2UsersUserIdReviewsGetParams;
  options?: AxiosRequestConfig,
}) => {
  return apiV2UsersUserIdReviewsGetFp({ params, options, });
};

